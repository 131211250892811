import React from "react";
import tw, { styled } from "twin.macro";
import { Link } from "gatsby";

const Single = tw.div`flex flex-col bg-white p-4 mb-4`;
const FirmName = tw.span`text-primary text-2xl font-bold`;
const Table = tw.table`table-auto`;
const Row = tw.tr`[:nth-of-type(odd)]:bg-gray-100 `;
const Cell = styled.td(({ $key }) => [
  tw`pr-16 text-left border-b-2 border-gray-200  [:last-child]:pr-2`,
  $key && tw`pt-1 pl-1 font-bold align-top`,
]);

const Rw = ({ title, value, children }) => (
  <Row>
    <Cell $key>{title}</Cell>
    <Cell>{value || children}</Cell>
  </Row>
);

const JobPosting = ({
  title,
  link,
  firmName,
  date,
  firmWebsite,
  description,
  ...rest
}) => {
  return (
    <Single {...rest}>
      <FirmName>{firmName}</FirmName>
      <Table>
        <tbody>
          <Rw title="Job Posted" value={date} />
          <Rw title="Position Title" value={title} />
          <Rw title="Company Name" value={firmName} />
          <Rw title="Company Website">
            <a
              href={firmWebsite}
              tw="text-primary hover:underline"
              target="_blank"
            >
              {firmWebsite}
            </a>
          </Rw>
          <Rw title="Job Description" value={description} />
          <Rw title="Job Posting Link">
            <Link to={link.url} tw="text-primary hover:underline">
              {link.title}
            </Link>
          </Rw>
        </tbody>
      </Table>
    </Single>
  );
};

export default JobPosting;
