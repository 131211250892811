import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { MultiSelect } from "@mantine/core";

import Job from "./JobPosting";

const Section = tw.section`flex flex-col w-full relative max-w-full items-start mt-12`;

const JobPostings = ({ ...rest }) => {
  const { jobs } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsJobPostings {
          job_postings {
            jobs: jobPostings {
              title
              link {
                ...Button
              }
              firmName
              description
              firmWebsite
              date: postingDate
            }
          }
        }
      }
    }
  `).wp.acfOptionsJobPostings.job_postings;
  const [selectedFirms, setSelectedFirms] = useState([]);

  const firms = jobs?.reduce((acc, { firmName }) => {
    if (!acc.includes(firmName)) {
      acc.push(firmName);
    }
    return acc;
  }, []);
  const data = firms?.map((firm) => ({
    label: firm,
    value: firm,
  }));

  return (
    <Section>
      <MultiSelect
        tw="self-end w-1/3 [label, input]:(font-din!)"
        label="Filter by Firm(s)"
        data={data}
        value={selectedFirms}
        onChange={setSelectedFirms}
        clearable
        searchable
      />
      {jobs?.length > 0 &&
        jobs.map((job, i) => {
          if (
            selectedFirms.length === 0 ||
            selectedFirms.includes(job.firmName)
          ) {
            return <Job key={i} {...job} />;
          }
        })}
    </Section>
  );
};

export default JobPostings;
