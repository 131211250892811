/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import InnerTitle from "../components/InnerTitle";
import JobPostings from "../components/JobPostings";
import PageContent from "../components/PageContent";

const JobPage = ({ data }) => {
  const { seo, title, content, sidebarMenuSelect } = data.wpPage;

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle>{title}</InnerTitle>
          <PageContent content={content} />
          <JobPostings />
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs menus={sidebarMenuSelect.sidebarMenus} />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default JobPage;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
      afterContentForm {
        form: afterContentForm
      }
    }
  }
`;
